<template>
<div>
<van-empty image="error">
<template #description>
<div style="color:red;font-size:20px;">操作失败</div>
</template>
</van-empty>

<van-cell-group title="错误明细">
 <van-cell title="错误代码" :label="respCode" :border="false"/>
 <van-cell title="错误原因" :label="respMsg" />
</van-cell-group>

</div>
</template>
<script>
import { Icon, Empty, Cell, CellGroup, Button } from 'vant';

export default {
 data() {
     return {
       respCode: this.$route.query.respCode === undefined ? '' : this.$route.query.respCode,
       respMsg: this.$route.query.respMsg === undefined ? '' : this.$route.query.respMsg, // '银行系统异常，请确认银行卡是否扣款或是否绑卡成功',
     };
   },
  components: {
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button
  }
};

</script>

